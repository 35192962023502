<template>
  <c-card class="row br6">
    <div class="header">
      <div class="header-item">
        <van-image
          class="avatar"
          :src="item.sex == 1 ? require('@/assets/images/patient_m.png') : require('@/assets/images/patient_w.png')"
        ></van-image>
        <span class="ml4">{{ item.patientName }}</span>
        <span class="ml4">{{ item.sexName }}</span>
        <span class="ml4 mr12">{{ item.age }}岁</span>
        <van-tag type="info" round v-if="item.recipeStatus === 2">待审核</van-tag>
        <van-tag type="danger" round v-if="item.recipeStatus === 3">审核驳回</van-tag>
        <van-tag type="primary" round v-if="item.recipeStatus === 4">审核通过</van-tag>
      </div>
      <div class="header-item">
      </div>
      <div class="header-item time">{{ item.createTime }}</div>
    </div>
    <div class="bottom">
      <span class="dark mr12">{{ item.deptName }}</span>
      <span class="normal mr12">处方医生: {{ item.doctorName }}</span>
      <span v-if="item.recipeStatus === 3" @click="showReject" class="yellow-color">查看驳回原因</span>
    </div>
    <template #footer>
      <div class="tac">
        <div class="primary" @click="toDetailPage">查看</div>
      </div>
    </template>
  </c-card>
</template>

<script>
import cCard from '@/components/cCard.vue';

export default {
  components: { cCard },
  name: 'ConsultRow',
  props: {
    item: Object,
  },
  methods: {
    toDetailPage() {
      this.$router.push(`/yaoshi/recipeDetail?recipeId=${this.item.id}`);
    },
    showReject() {
      this.$dialog.alert({
        message: this.item.rejectedReason,
      })
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .header-item {
    display: flex;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 2px;
      background: #f00;
    }
  }

  .time {
    color: #999;
    width: 90px;
    text-align: center;
  }
}
</style>
