<template>
  <div class="c-card" :class="customClass">
    <template v-if="$slots.header">
      <slot name="header"></slot>
      <van-divider></van-divider>
    </template>
    <slot></slot>
    <template v-if="$slots.footer">
      <van-divider />
      <slot name="footer"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CommonCard',
  props: {
    customClass: String,
  },
};
</script>

<style lang="less" scoped>
@import "../assets/style/theme.less";
.c-card {
  margin-bottom: 12px;
  padding: 12px;
  background-color: @white;
  // border-radius: 6px;
  position: relative;
  &-title {
    font-size: 16px;
    color: @dark;
    font-weight: 500;
    &.single-line {
      margin-bottom: 0;
    }
  }
  &-arrow {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 10px;
  }
  &-row {
    margin-bottom: 6px;
  }
  &-label {
    color: @info;
    display: inline-block;
    width: 100px;
    vertical-align: top;
  }
  &-value {
    color: @normal;
    display: inline-block;
    width: calc(100% - 100px);
    word-break: break-word;
  }
  &-footer {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }
  /deep/ .van-button--small {
    font-size: 14px;
    height: 24px;
    margin-left: 8px;
  }
  // /deep/ .van-cell {
  //   padding: 0;
  //   .van-cell__title {
  //     font-size: 16px;
  //     font-weight: 500;
  //   }
  // }
}
</style>
