<template>
  <van-row class="radio-button-group" :style="{height}">
    <van-col
      v-for="btn in btnList"
      :key="btn.value"
      :span="24 / btnList.length"
      class="radio-button"
      :class="{active: type === btn.value}"
      @click="onClick(btn.value)"
    >
      {{ btn.label }}
    </van-col>
  </van-row>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    type: {
      type: [String, Number],
      default: '任务',
    },
    btnList: {
      type: Array,
      default: () => ([]),
    },
    height: {
      type: String,
      default: '40px',
    },
  },
  methods: {
    onClick(type) {
      this.$emit('update:type', type);
      this.$emit('change', type);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/style/theme.less";
.radio-button-group {
  margin-bottom: 12px;
  text-align: center;
  .radio-button {
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #F3F7F9;
    border: 1px solid #F3F7F9;
    color: @normal;
    &.active {
      background-color: @primary;
      border-color: @primary;
      color: @white;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-color: transparent;
    }
  }
}
</style>
