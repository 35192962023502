<template>
  <div class="list">
    <radio-button
      class="page-top"
      :type.sync="recipeStatus"
      :btnList="[
        { label: `待审核(${needAudit})`, value: '2' },
        { label: `审核驳回(${refused})`, value: '3' },
        { label: `审核通过(${agree})`, value: '4' },
      ]"
      @change="tabChange"
    ></radio-button>
    <div class="page-top">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        offset="100"
        class="list-container"
        @load="getList"
      >
        <receipe-row
          v-for="item in list"
          :key="item.id"
          :item="item"
        ></receipe-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import radioButton from '@/components/radioButton.vue';
import ReceipeRow from './components/receipe-row.vue';
export default {
  components: { radioButton, ReceipeRow },
  name: 'List',
  data() {
    return {
      recipeStatus: this.$route.query.recipeStatus || '2',

      pageInfo: {
        size: 10,
        current: 1,
        total: 0,
      },
      loading: false,
      finished: false,
      list: [],
      agree: 0,
      refused: 0,
      needAudit: 0,
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    tabChange() {
      this.list = [];
      this.pageInfo.current = 1;
      this.getList();
      this.$router.replace({
        path: this.$route.path,
        query: {
          recipeStatus: this.recipeStatus,
        }
      })
    },
    getData() {
      this.$post('/api/blade-consult/drugDoctorWork/getNeedAuditRecipeCount')
        .then(({ data }) => {
          this.needAudit = data.needAudit;
          this.refused = data.refused;
          this.agree = data.agree;
        })
    },
    getList() {
      this.loading = true;
      this.$post('/api/blade-consult/drugDoctorWork/page', {
        ...this.pageInfo,
        recipeStatus: this.recipeStatus,
      })
        .then(({ data }) => {
          this.list = [...this.list, ...data.records];
          this.pageInfo.total = data.total;
          this.pageInfo.current += 1;
          this.finished = this.pageInfo.total <= this.list.length;
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
</script>

<style lang="less" scoped>
.list-container {
  max-height: calc(100vh - 64px);
  overflow: auto;
  padding: 12px;
}
</style>
